import { useState, useRef } from "react";
import { db, storage } from "../firebase/firebase";
import { collection, addDoc } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddDocumentForm() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [date, setDate] = useState("");
  const [url, setUrl] = useState("");
  const [image, setImage] = useState(null);
  const fileInputRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let imageUrl = null;

      // If there's an image, upload it to Firebase Storage and get the URL
      if (image) {
        const imageRef = ref(storage, `itemImages/${Date.now()}_${image.name}`);
        const snapshot = await uploadBytes(imageRef, image);
        imageUrl = await getDownloadURL(snapshot.ref);
      }

      // Add document to Firestore
      await addDoc(collection(db, "testCollection"), {
        Title: title,
        Description: description,
        Category: category,
        Date: new Date(date),
        Point: 0,
        Image: imageUrl,
        Url: url,
      });

      // Reset form
      setTitle("");
      setDescription("");
      setCategory("");
      setDate("");
      setImage(null);
      setUrl("");

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }

      toast.success("Document added succesfully");
    } catch (error) {
      toast.error("Document add failed");

    }
  };

  return (
    <>
      <div className="bg-base-300 p-6 rounded shadow-lg w-1/3 h-max min-w-[330px]">
        <h2 className="text-2xl font-bold mb-4">Add New Document</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block">Title</label>
            <input
              type="text"
              className="input input-bordered w-full max-w-xs"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Write the title"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block ">Description</label>
            <textarea
              className="textarea textarea-bordered"
              rows="4"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Write a short description"
              required
            ></textarea>
          </div>
          <div className="mb-4">
            <label className="block">Category</label>
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
              className="select select-bordered w-full max-w-xs"
            >
              <option value="" disabled>
                Select the Category?
              </option>
              <option value="Spor">Spor</option>
              <option value="Kampanya">Kampanya</option>
              <option value="Dizi / Film">Dizi / Film</option>
              <option value="Etkinlikler">Etkinlikler</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block">Date</label>
            <input
              type="datetime-local"
              className="input input-bordered w-full max-w-xs"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block">URL</label>
            <input
              type="text"
              className="input input-bordered w-full max-w-xs"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block">Photo</label>

            <input
              type="file"
              className="file-input file-input-bordered w-full max-w-xs"
              ref={fileInputRef}
              onChange={(e) => setImage(e.target.files[0])}
            />
          </div>
          <div>
            <button type="submit" className="btn">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddDocumentForm;
