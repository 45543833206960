import { PiCalendarDotsDuotone } from "react-icons/pi";
import { useState } from "react";
import ThemeControl from "./ThemeControl";
import { useNavigate } from "react-router-dom";

function NavBar({ pressDate, type, pressCategory }) {
  const [clickedDay, setclickedDay] = useState("Bu Hafta");
  const navigate = useNavigate()
  const days = [
    "Bu Hafta",
    "Bugün",
    "Yakında",
  ];
  function dayClick(day) {
    setclickedDay(day);
    pressDate(day);    
  }

  function homePage(){
    navigate(0)
  }


  return (
    <header className="navbar flex max-sm:justify-between justify-around relative z-50">
      <h1 onClick={() => homePage("Bu Hafta")} className="btn btn-ghost text-2xl font-josefin font-semibold">{type}</h1>
      <div className="flex gap-4">
        <ThemeControl />
        {type === "Bugün Ne Var ?" &&
        <div className="dropdown dropdown-hover dropdown-bottom dropdown-end">
          <div tabIndex={0} role="button" className="mr-4">
            <PiCalendarDotsDuotone
              style={{ fontSize: "30px"}}
            />
          </div>
          <ul
            tabIndex={0}
            className="dropdown-content menu bg-base-100 rounded-box w-52 p-2 shadow"
          >
            {days.map((day) => (
              <li key={day}>
                <p
                  className={clickedDay === day ? "active": undefined}
                  onClick={() => dayClick(day)}
                >
                  {day}
                </p>
              </li>
            ))}
          </ul>
        </div>
        }
      </div>
    </header>
  );
}

export default NavBar;
