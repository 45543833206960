import React from 'react';
import Select from 'react-select';

function ListTitle({ title, date, category, pressCategory }) {
  const options = [
    { value: 'Genel', label: 'Genel' },
    { value: 'Etkinlikler', label: 'Etkinlikler' },
    { value: 'Dizi / Film', label: 'Dizi / Film' },
    { value: 'Spor', label: 'Spor' },
    { value: 'Kampanya', label: 'Kampanya' },
  ];

  function handleChange(selectedOption) {
    pressCategory(selectedOption.value);
  }

  // const customStyles = {
  //   control: (provided, state) => ({
  //     ...provided,
  //     border: 'none', // Remove border
  //     boxShadow: 'none', // Remove box shadow
  //     '&:hover': {
  //       border: 'none' // Remove border on hover
  //     }
  //   }),
  // };

  return (
    <>
      <Select
        value={options.find(option => option.value === category)}
        onChange={handleChange}
        options={options}
        className="my-react-select-container mb-2 sm:hidden z-30 text-l font-bold min-w-[150px]"
        // styles={customStyles} 
        isSearchable={false}
        classNamePrefix={"my-react-select"}
      />
      <div className="max-sm:hidden flex flex-col items-center">
        <p className="text-2xl max-sm:text-xl font-bold subpixel-antialiased">
          {title}
        </p>
        <p className="text-l">{date}</p>
      </div>
    </>
  );
}

export default ListTitle;
