
function Category({ pressCategory, category }) {
  const categories = ["Genel","Etkinlikler","Dizi / Film", "Spor","Kampanya"];

  function clickHandler(item) {
    pressCategory(item);
  }
  return (
    <nav>
     
      <ul
        className="max-sm:hidden menu menu-horizontal flex justify-center mb-7 "
      >
        <div className="flex flex-row border-2 rounded-2xl">
          <li className="menu-title">Kategoriler</li>
          {categories.map((item) => (
            <li key={item}>
              <p
                className={
                  category === item
                    ? "active rounded-2xl"
                    : "hover: rounded-2xl"
                }
                onClick={clickHandler.bind(null, item)}
              >
                {item}
              </p>
            </li>
          ))}
        </div>
      </ul>
    </nav>
  );
}

export default Category;
