import { useState } from "react";
import { db } from "../firebase/firebase";
import { doc, updateDoc, deleteDoc } from "firebase/firestore";
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EditItem({ id, image, title, description, point, date, url }) {
  const timestampToDateTimeLocal = (timestamp) => {
    const date = timestamp.toDate();
    const pad = (number) => number.toString().padStart(2, "0");
  
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
  
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
  



  const [newTitle, setNewTitle] = useState(title);
  const [newDescription, setNewDescription] = useState(description);
  const [newPoint, setNewPoint] = useState(point);
  const [newUrl, setNewUrl] = useState(url);
  const [newDate, setNewDate] = useState(timestampToDateTimeLocal(date));




  const handleUpdate = async () => {
    const itemDoc = doc(db, "testCollection", id); // Adjust the collection and document ID as needed
    try {
      await updateDoc(itemDoc, {
        Title: newTitle,
        Description: newDescription,
        Point: parseInt(newPoint),
        Url: newUrl,
        Date: new Date(newDate)
      });
      toast.success("Document updated succesfully");
    } catch (error) {
      toast.error("Document update failed");
    }
  };

  const handleDelete = async () => {
    const itemDoc = doc(db, "testCollection", id);
    try {
      await deleteDoc(itemDoc);
      toast.success("Document deleted succesfully");
    } catch (error) {
      toast.error("Document update failed");
    }
  };

  return (
    <>
      <div className="flex flex-row bg-base-300 mt-2 rounded-lg p-4 w-3/4 max-sm:w-5/6 min-w-[160px] z-10">
        <div className="flex">
          <img className="w-20 h-20 rounded-md" src={image} alt="itemPhoto" />
        </div>
        <div className="flex flex-col flex-1 ml-5">
          <input
            type="text"
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
            className="input input-bordered w-full max-w-xs text-xl font-bold mb-2"
          />
          <input
            type="text"
            value={newDescription}
            onChange={(e) => setNewDescription(e.target.value)}
            className="input input-bordered w-full max-w-xs"
          />
        </div>
        <div className="flex flex-col items-end rounded-full">
          <div className="flex max-h gap-4 border border-gray-500 rounded-lg p-1">
            <input
              type="number"
              value={newPoint}
              onChange={(e) => setNewPoint(e.target.value)}
            />
          </div>
          <div className="flex max-h gap-4 border border-gray-500 rounded-lg p-1">
            <input
              type="text"
              value={newUrl}
              onChange={(e) => setNewUrl(e.target.value)}
            />
          </div>
          <div className="flex max-h gap-4 border border-gray-500 rounded-lg p-1">
            <input
              type="datetime-local"
              value={newDate}
              onChange={(e) => setNewDate(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex items-end ml-4 mb-2">
            <button onClick={handleDelete} className="btn btn-error">
              DELETE
            </button>
          </div>
          <div className="flex items-end ml-4">
            <button onClick={handleUpdate} className="btn btn-warning">
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditItem;
