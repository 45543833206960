import { useState, useEffect, useMemo } from "react";
import Category from "../components/Category";
import ListItem from "../components/List/ListItem";
import ListTitle from "../components/List/ListTitle";
import NavBar from "../components/NavBar";
import {fetchThisWeeksDocuments, fetchUpcomingDocuments} from "../api/api";
import {isWithinInterval, startOfDay, endOfDay, addDays } from 'date-fns';

function MainPage() {
  const [category, setCategory] = useState("Genel");
  const [date, setDate] = useState("Bu Hafta");
  const [documents, setDocuments] = useState([]);
  const [thisWeeksDocuments, setThisWeeksDocuments] = useState([]);
  const [upcomingDocuments, setUpcomingDocuments] = useState([]);

  function pressCategory(item) {
    setCategory(item);
  }

  function pressDate(item) {
    setDate(item);
  }

  useEffect(() => {
    async function fetchData() {
      if (date === "Bu Hafta" && thisWeeksDocuments.length === 0) {
        const fetchedDocuments = await fetchThisWeeksDocuments();
        setThisWeeksDocuments(fetchedDocuments);
        setDocuments(fetchedDocuments);
      } else if (date === "Yakında" && upcomingDocuments.length === 0) {
        const fetchedDocuments = await fetchUpcomingDocuments();
        setUpcomingDocuments(fetchedDocuments);
        setDocuments(fetchedDocuments);
      } else if (date === "Bu Hafta") {
        setDocuments(thisWeeksDocuments);
      } else if (date === "Yakında") {
        setDocuments(upcomingDocuments);
      }
    }

    fetchData();
    //eslint-disable-next-line
  }, [date]);


  const filteredDocuments = useMemo(() => {
    const now = new Date();
    const startOfDayDate = startOfDay(now);
    const endOfDayDate = endOfDay(now);
    const endOfWeekDate = endOfDay(addDays(now, 7));

    const docs = documents.filter(doc => {
      const docDate = new Date(doc.Date.seconds * 1000); // Assuming the timestamp is a Firestore timestamp

      if (date === "Bu Hafta") {
        return isWithinInterval(docDate, { start: startOfDayDate, end: endOfWeekDate });
      } else if (date === "Bugün") {
        return isWithinInterval(docDate, { start: startOfDayDate, end: endOfDayDate });
      }else if (date === "Yakında") {
        return docDate > endOfWeekDate;
      }
      return false;
    });

    if (category === "Genel") {
      return docs.sort((a, b) => b.Point - a.Point);
    } else {
      return docs
        .filter((item) => item.Category === category)
        .sort((a, b) => b.Point - a.Point);
    }
  }, [documents, category, date]);

  

  return (
    <div className="bg-base-100">
      <NavBar type={"Bugün Ne Var ?"} pressDate={pressDate} pressCategory={pressCategory} />
      <Category pressCategory={pressCategory} category={category} />
      <main className="flex h-max ">
        <div className="flex flex-col w-full items-center ">
          <ListTitle
            title={category}
            date={date}
            category={category}
            pressCategory={pressCategory}
          />
          {filteredDocuments.map((type, index) => (
            <ListItem
              key={type.id}
              rank={index + 1}
              title={type.Title}
              description={type.Description}
              image={type.Image}
              id={type.id}
              point={type.Point}
              url={type.Url}
              date ={type.Date}
            />
          ))}
        </div>
      </main>
    </div>
  );
}

export default MainPage;
