import {
  PiArrowFatUp,
  PiArrowFatUpFill,
  PiArrowFatDown,
  PiArrowFatDownFill,
  PiArrowSquareOut,
} from "react-icons/pi";
import { useState, useEffect } from "react";
import { db } from "../../firebase/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { fetchPointById } from "../../api/api";

function ListItem({ rank, title, description, image, id, point, url, date }) {
  const [clickedUp, setClickedUp] = useState(false);
  const [clickedDown, setClickedDown] = useState(false);
  const [firePoint, setfirePoint] = useState(point);

  const formatDate = (timestamp) => {
    const date = timestamp.toDate();
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based, so add 1
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const getHour = (timestamp) => {
    const date = timestamp.toDate();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}.${minutes}`;
  };

  useEffect(() => {
    const upState = localStorage.getItem(`upArrow_${id}`)
      ? localStorage.getItem(`upArrow_${id}`)
      : "false";
    const downState = localStorage.getItem(`downArrow_${id}`)
      ? localStorage.getItem(`downArrow_${id}`)
      : "false";
    setClickedUp(JSON.parse(upState));
    setClickedDown(JSON.parse(downState));
  }, [id]);

  const handleUpdate = async (newVote) => {
    const itemDoc = doc(db, "testCollection", id); // Adjust the collection and document ID as needed
    try {
      const newPoint = await fetchPointById(id);
      await updateDoc(itemDoc, {
        Point: newPoint + newVote,
      });
      setfirePoint(newPoint + newVote);
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  async function handleClick(version) {
    if (version === "up") {
      const newVote = clickedDown ? 2 : 1;
      setClickedUp(true);
      setClickedDown(false);
      localStorage.setItem(`upArrow_${id}`, "true");
      localStorage.setItem(`downArrow_${id}`, "false");
      handleUpdate(newVote);
    } else {
      const newVote = clickedUp ?  -2 :  -1;
      setClickedDown(true);
      setClickedUp(false);
      localStorage.setItem(`downArrow_${id}`, "true");
      localStorage.setItem(`upArrow_${id}`, "false");
      handleUpdate(newVote);
    }
  }

  return (
    <article className="flex flex-col bg-base-200 dark:bg-neutral mb-2 rounded-lg p-4 w-3/4 max-sm:w-5/6 max-w-[1200px] z-10">
      <div className="flex">
        <div
          className="max-sm:size-20 size-36 bg-contain bg-no-repeat bg-center flex-shrink-0"
          style={{ backgroundImage: `url(${image})` }}
        ></div>
        <div className="flex flex-col ml-5 flex-1">
          <p className="text-xl max-sm:text-base font-bold subpixel-antialiased">
            {rank}.&nbsp;{title}
          </p>
          <p>{description}</p>
        </div>
        {url && (
          <a href={url} target="_blank" rel="noopener noreferrer" className="ml-auto h-max">
            <PiArrowSquareOut size={"20px"} className="cursor-pointer ml-2" />
          </a>
        )}
      </div>

      <div className="flex flex-row items-end justify-between rounded-full">
        <div className="flex">
          <div className="flex flex-col items-end">
            <div className="flex flex-row items-end opacity-100">
              <span className="badge">{formatDate(date)}</span>
              <span className="badge">{getHour(date)}</span>
            </div>
          </div>
        </div>

        <div className="flex max-h border border-gray-500 rounded-lg p-1 mt-auto">
          {clickedUp === true ? (
            <PiArrowFatUpFill style={{ fontSize: "20px" }} />
          ) : (
            <PiArrowFatUp
              className="cursor-pointer"
              style={{ fontSize: "20px" }}
              onClick={() => handleClick("up")}
            />
          )}
          <p className="mx-2">{firePoint}</p>
          {clickedDown === true ? (
            <PiArrowFatDownFill style={{ fontSize: "20px" }} />
          ) : (
            <PiArrowFatDown
              className="cursor-pointer"
              style={{ fontSize: "20px" }}
              onClick={() => handleClick("down")}
            />
          )}
        </div>
      </div>
    </article>
  );
}

export default ListItem;
