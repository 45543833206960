import React, { useState, useEffect, useMemo } from "react";
import NavBar from "../components/NavBar";
import AddDocumentForm from "../components/AddDocumentForm";
import { fetchAllDocuments } from "../api/api";
import EditItem from "../components/EditItem";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {startOfDay, isAfter} from 'date-fns';



function Admin() {
  const [allDocuments, setAllDocuments] = useState([]);

  useEffect(() => {
    async function documentFetch() {
      const fetchedDocuments = await fetchAllDocuments();
      setAllDocuments(fetchedDocuments);
    }

    documentFetch();
  }, []);

  const filteredDocuments = useMemo(() => {
    const now = new Date();
    const startOfDayDate = startOfDay(now);

    return allDocuments.filter(doc => {
      const docDate = new Date(doc.Date.seconds * 1000); // Assuming the timestamp is a Firestore timestamp
      return isAfter(docDate, startOfDayDate) || docDate.getTime() === startOfDayDate.getTime();
    });
  }, [allDocuments]);

  //   {/* You can open the modal using document.getElementById('ID').showModal() method */}

  return (
    <>
      <ToastContainer />
      <NavBar type={"Admin"} />
      <div className="flex flex-col w-full justify-around items-center">
        <button
          className="btn mb-2"
          onClick={() => document.getElementById("my_modal_3").showModal()}
        >
          Add New Document
        </button>
        <dialog id="my_modal_3" className="modal">
          <div className="modal-box w-max">
            <form method="dialog">
              {/* if there is a button in form, it will close the modal */}
              <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                ✕
              </button>
            </form>
            <AddDocumentForm />
          </div>
        </dialog>
        <div className="flex flex-col w-full items-center p-4">
          {filteredDocuments.map((type, index) => (
            <EditItem
              key={type.id}
              rank={index + 1}
              point={type.Point}
              title={type.Title}
              description={type.Description}
              id={type.id}
              image={type.Image}
              url={type.Url}
              date={type.Date}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default Admin;
