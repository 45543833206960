import React, { useState } from "react";
import NavBar from "../components/NavBar";
import { useNavigate } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  browserSessionPersistence,
  setPersistence,
} from "firebase/auth";
import { auth } from "../firebase/firebase";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await setPersistence(auth, browserSessionPersistence);
      await signInWithEmailAndPassword(auth, username, password);
      navigate("/adminti");
    } catch (error) {
      console.error("Error during login/signup:", error);
    }
  };

  return (
    <>
      <NavBar type={"Admin Login"} />
      <div className="flex justify-center items-center h-[calc(100vh-70px)]">
        <div className="min-w-96 w-1/3 max-w-2xl p-10 shadow-lg bg-white rounded-md border-2 border-dune">
          <h1 className="text-center text-lg font-semibold">Login</h1>
          <hr className="mt-3" />
          <form onSubmit={handleLogin}>
            <div className="mt-3">
              <label htmlFor="username" className="block text-base mb-2">
                E-Mail
              </label>
              <input
                type="email"
                id="username"
                required
                minLength={4}
                className="border w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-400 bg-white rounded-md"
                placeholder="Enter your e-mail"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="mt-3">
              <label htmlFor="password" className="block text-base mb-2">
                Password
              </label>
              <input
                type="password"
                id="password"
                required
                minLength={6}
                className="border w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-400 bg-white rounded-md"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="flex justify-between items-center mt-6">
              <button type="submit" className="btn flex-1 mr-3">
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
