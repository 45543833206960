import { db } from '../firebase/firebase.js';
import { collection, query, where, getDocs,doc,getDoc } from "firebase/firestore";
import { startOfDay, addDays, endOfDay } from 'date-fns';

export async function fetchDocumentsByCategory(category) {
  const colRef = collection(db, 'testCollection');
  const q = query(colRef, where("Category", "==", category));

  try {
    const querySnapshot = await getDocs(q);
    const documents = [];

    querySnapshot.forEach((doc) => {
      documents.push({ id: doc.id, ...doc.data() }); // Include the document ID
    });

    return documents;
  } catch (error) {
    console.error("Error fetching documents: ", error);
  }
}

export async function fetchAllDocuments() {
  const colRef = collection(db, 'testCollection');

  try {
    const querySnapshot = await getDocs(colRef);
    const documents = [];

    querySnapshot.forEach((doc) => {
      documents.push({ id: doc.id, ...doc.data() }); // Include the document ID
    });

    return documents;
  } catch (error) {
    console.error("Error fetching documents: ", error);
  }
}

export async function fetchThisWeeksDocuments() {
  const allDocuments = await fetchAllDocuments();

  const today = startOfDay(new Date());
  const sevenDaysLater = endOfDay(addDays(today, 7));

  const thisWeeksDocuments = allDocuments.filter((doc) => {
    const docDate = new Date(doc.Date.seconds * 1000); // assuming the timestamp is a Firestore timestamp
    return docDate >= today && docDate <= sevenDaysLater;
  });

  return thisWeeksDocuments;
}

export async function fetchUpcomingDocuments() {
  const allDocuments = await fetchAllDocuments();
  const today = startOfDay(new Date());
  const sevenDaysLater = endOfDay(addDays(today, 7));

  const upcomingDocuments = allDocuments.filter((doc) => {
    const docDate = new Date(doc.Date.seconds * 1000); // assuming the timestamp is a Firestore timestamp
    return docDate > sevenDaysLater;
  });

  return upcomingDocuments;
}

export async function fetchPointById(id) {
  const docRef = doc(db, 'testCollection', id);

  try {
    const docSnapshot = await getDoc(docRef);
    
    if (docSnapshot.exists()) {
      return docSnapshot.data().Point;
    } else {
      console.error("No document found with the given ID");
    }
  } catch (error) {
    console.error("Error fetching document: ", error);
  }
}
